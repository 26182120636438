import AI from '../images/AI.jpg';
import Cloud from '../images/Cloud.jpg';
import Consulting from '../images/Consulting.jpg';
import Data from '../images/Data.jpg';
import Design from '../images/Design.jpg';
import Product from '../images/Product.jpg';
import FooterComponent from './Footer';
import HeaderComponent from './Header';




const ContactUs = (props) => {
	

	return (

		<div>
			
			<HeaderComponent />
			
			<div class="container-fluid text-bg-success bg-opacity-25">
				<br />

				<h3 class="display-7 text-center  text-info-emphasis"> <b> Simple & Reliable Information Technology Solutions </b></h3>

				<br />

				<div class="row">
					<div class="col-sm-12 mb-3 mb-sm-0">
						<div class="card">
							<div class="card-body">
								<h5 class="card-title text-success">Contact Us</h5>
								<br />
								
								<h6 class="display-7 text-center"> <b>Email: </b> Please send us email to <a href="mailto:mosama@sarits.com"> sarits-client-relations</a>.  We will respond to your email as soon as possible. </h6> <br />				
								<h6 class="display-7 text-center"> <b>Phone: </b> You can call us at: 1 (202) 350-1308  </h6> <br />
								
							</div>
						</div>
					</div>				
				</div>
				<br />			
				<FooterComponent />
			</div>


		</div>
	);
};

export default ContactUs;