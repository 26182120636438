const config = {};

config.secret = process.env.REACT_APP_SECRET;
config.port = process.env.PORT || 3001;

config.apiKey=process.env.REACT_APP_OPENAI_API_KEY;
config.dangerouslyAllowBrowser=process.env.REACT_APP_ALLOW_BROWSER

config.weaviate_endpoint=process.env.REACT_APP_WEAVIATE_ENDPOINT
config.weaviate_apikey=process.env.REACT_APP_WEAVIATE_API_KEY

config.pinecone_apikey=process.env.REACT_APP_PINECONE_API_KEY
config.pinecone_env=process.env.REACT_APP_PINECONE_ENVIRONMENT
config.pinecone_endpoint=process.env.REACT_APP_PINECONE_ENDPPOINT


module.exports = config;