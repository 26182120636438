import AI from '../images/AI.jpg';
import Cloud from '../images/Cloud.jpg';
import Consulting from '../images/Consulting.jpg';
import Data from '../images/Data.jpg';
import Design from '../images/Design.jpg';
import Product from '../images/Product.jpg';
import FooterComponent from './Footer';
import HeaderComponent from './Header';




const Home = (props) => {
	//alert("in start here 1");
	function handleSubmit(event) {
		event.preventDefault();
	}

	return (

		<div>
			
			<HeaderComponent />
			
			<div class="container-fluid text-bg-success bg-opacity-25">
				<br />

				<h3 class="display-7 text-center  text-info-emphasis"> <b> Secure & Reliable Information Technology Solutions </b></h3>

				<br />

				<div class="row">
					<div class="col-sm-4 mb-3 mb-sm-0">
						<div class="card">
							<div class="card-body">
								<h5 class="card-title text-success">Artificial Intelligence (AI)</h5>
								<br />
								<img src={AI} class="rounded" />
								<br />
								<br />
								<p class="card-text fs-7 fw-lighter">Expert in architecting, designing, and building robust systems that leverage the latest industry-standard Artificial Intelligence and Machine Learning concepts, services and technologies. </p>
								<a href="#" class="link-info link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">More Details</a>
							</div>
						</div>
					</div>
					<div class="col-sm-4">
						<div class="card">
							<div class="card-body">
								<h5 class="card-title text-success">Cloud Migrations</h5>
								<br />
								<img src={Cloud} class="rounded" />
								<br />
								<br />
								<p class="card-text fs-7 fw-lighter">We provide strategy, roadmap and implementation for cloud migrations - ranging from simple workloads to entire set of systems hosted on organization's data centers.  </p>
								<a href="#" class="link-info link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">More Details</a>
							</div>
						</div>
					</div>
					<div class="col-sm-4">
						<div class="card">
							<div class="card-body">
								<h5 class="card-title text-success">Data Anaytics</h5>
								<br />
								<img src={Data} class="rounded" />
								<br />
								<br />
								<p class="card-text fs-7 fw-lighter">Design next-generation data and analytic platforms and systems based on organizations data insight and reporting requirements. Define corporate data models and data domains. </p>
								<a href="#" class="link-info link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">More Details</a>
							</div>
						</div>
					</div>
				</div>

				<br />

				<div class="row">
					<div class="col-sm-4 mb-3 mb-sm-0">
						<div class="card">
							<div class="card-body">
								<h5 class="card-title text-success">Architecture & Design</h5>
								<br />
								<img src={Design} class="rounded" />
								<br />
								<br />
								<p class="card-text fs-7 fw-lighter">We define conceptual, logical and physical architectures and designs for systems that include details across all the architectural domains; Business, Data, Application, Technology and UX. </p>
								<a href="#" class="link-info link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">More Details</a>
							</div>
						</div>
					</div>
					<div class="col-sm-4">
						<div class="card">
							<div class="card-body">
								<h5 class="card-title text-success">Product / System Development</h5>
								<br />
								<img src={Product} class="rounded" />
								<br />
								<br />
								<p class="card-text fs-7 fw-lighter">Implement IT products and systems fully customized towards organizations requirements and functionality, aligned with organization's strategy, technical footprints and strategic direction  </p>
								<a href="#" class="link-info link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">More Details</a>
							</div>
						</div>
					</div>
					<div class="col-sm-4">
						<div class="card">
							<div class="card-body">
								<h5 class="card-title text-success">IT Consulting Services</h5>
								<br />
								<img src={Consulting} class="rounded" />
								<br />
								<br />
								<p class="card-text fs-7 fw-lighter">General IT consulting service across all the line of businesses such as financial services, manufacturing, health and service industry.</p>
								<a href="#" class="link-info link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">More Details</a>
							</div>
						</div>
					</div>
				</div>
				<br />				
				<FooterComponent />
			</div>

		</div>
	);
};

export default Home;