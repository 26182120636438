import { useNavigate } from 'react-router-dom';
import logo from '../images/SARITS_Logo.jpg';;

const Header = () => {

	return (
		<>

			<nav class="navbar navbar-expand-lg bg-body-tertiary bg-dark" data-bs-theme="dark">
				<div class="container-fluid">
				
					<img src={logo} alt="Logo" width="30" height="24" class="d-inline-block align-text-top"/> &nbsp; &nbsp;
				
					<a class="navbar-brand" href="/"><b>SARITS</b> - NEXT GENERATION IT SOLUTIONS </a>
					<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
						<span class="navbar-toggler-icon"></span>
					</button>
					<div class="collapse navbar-collapse" id="navbarText">
						<ul class="navbar-nav me-auto mb-2 mb-lg-0">
							<li class="nav-item">
								<a class="nav-link active" aria-current="page" href="/">Home</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="/ContactUs">Contact Us</a>
							</li>						
						</ul>
						
					</div>
				</div>
			</nav>


		</>
	);
};

export default Header;