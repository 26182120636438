import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import HomeComponent from './components/Home';
import ContactComponent from './components/ContactUs';
import GenTextfromCapturedAudioComponent from './components/GenTextfromCapturedAudioChatbot';
import './App.css';

function App() {
	return (
		<div className="App">
			<Router>
				<Routes>
					<Route index element={<HomeComponent />} >
					</Route>
					<Route path="ContactUs" element={<ContactComponent />} /> 
					<Route path="Home" element={<HomeComponent />} />
					<Route path="nanv" element={<GenTextfromCapturedAudioComponent />} />
				</Routes>

			</Router>
		</div>
	);
}

export default App;