import { useNavigate } from 'react-router-dom';


const Footer = () => {

	return (
		<>
		
			<hr />
			<div class="card-body text-center">
				<h7 class="display-7 text-center"><a class="link-dark" href="/ContactUs">Contact Us</a></h7>				
				<br />
			</div>
			<br/>


		</>
	);
};

export default Footer;