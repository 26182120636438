import { useState } from "react"

const OpenAIApi = require("openai");
const Configuration = require("../config");
const axios = require('axios');
let localVideo = document.getElementById('localVideo');
let chunks = [];
let mediaRecorder;


const GenTextfromCapturedAudioChatbot = () => {

	const openai = new OpenAIApi(Configuration);
	//const [prompt, setPrompt] = useState("");
	const [apiResponse, setApiResponse] = useState("");
	const [fullThread, setfullThread] = useState([]);
	const [loading, setLoading] = useState(false);
	const [recording, setRecording] = useState(false);
	//let [mp3File, setmp3File] = useState(null);

	const startRecording = async () => {
		const mimeType = 'video/webm;codecs=vp8,opus';

		localVideo = document.getElementById('localVideo');

		//alert(localVideo)

		if (!MediaRecorder.isTypeSupported(mimeType)) {
			alert('vp8/opus mime type is not supported');

			return;
		}

		const options = {
			audioBitsPerSecond: 128000,
			mimeType,
			videoBitsPerSecond: 2500000
		}

		const mediaStream = await getLocalMediaStream();

		mediaRecorder = new MediaRecorder(mediaStream, options);

		setListeners();

		mediaRecorder.start(1000);

		setRecording(true)
	};

	const getLocalMediaStream = async () => {
		const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
		localVideo.srcObject = mediaStream;

		return mediaStream;
	};

	const setListeners = () => {
		mediaRecorder.ondataavailable = handleOnDataAvailable;
		mediaRecorder.onstop = handleOnStop;
	};

	const handleOnDataAvailable = ({ data }) => {
		if (data.size > 0) {
			chunks.push(data);
		}
	};

	const handleOnStop = () => {
		saveFile();

		destroyListeners();
		mediaRecorder = undefined;
	};

	const destroyListeners = () => {
		mediaRecorder.ondataavailable = undefined;
		mediaRecorder.onstop = undefined;
	};

	const stopRecording = async () => {
		if (!mediaRecorder) return;

		mediaRecorder.stop();
		setRecording(false)
	};
	
	const reset = async () => {
		setfullThread([]);
		setApiResponse("");
		
	};

	const saveFile = async () => {
		const blob = new Blob(chunks);

		const blobUrl = URL.createObjectURL(blob);
		//alert(await blob.arrayBuffer())
		const ab = await blob.arrayBuffer();
		const file = new File([ab], "speech.mp3")
		//alert(file)
		
		handleSubmit(file)
		
		/*
		//this code is a good example of locally saving a file
		const link = document.createElement('a');
		link.style = 'display: none';
		link.href = blobUrl;
		link.download = 'recorded_file.webm';

		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);

		window.URL.revokeObjectURL(blobUrl);
		
		*/
		chunks = [];
	};


	const handleSubmit = async (file) => {
		setLoading(true);
		const inputFile = file;

		try {
			let transcription = await openai.audio.transcriptions.create({
				model: "whisper-1",
				file: file
			})

			console.log(transcription.text);

			setApiResponse(transcription.text);
			const tempT = fullThread;
			tempT.push(transcription.text)
			setfullThread(tempT)

		} catch (e) {
			console.log(e);
			setApiResponse("Something is going wrong, Please try again." + e);
			alert("ERROR: " + e)
		}
		setLoading(false);

	};


	return (
		<>


			<br />
			<h3 class="display-7 text-center  text-success"> Generate Text from Audio Captured on screen</h3><br />
			<div class="row">
				<div class="col-sm-3">
				</div>
				<div class="col-sm-6">
					<h6 class="display-7 text-center  text-info"> Purpose: Captures an audio on screen and generates text from that audio</h6>
					<br /><br />
				</div>
				<div class="col-sm-3">
				</div>
			</div>
			<form onSubmit={handleSubmit}>

				<div class="row justify-content-center">
					<div class="col-sm-3">
					</div>
					<div class="col-sm-6 justify-content-center text-center">
						<label> Please click the below microphone icon to start and stop the recording</label> <br /> <br />
						<div class="text-center align-middle">
							{
								recording ?
									<>
										<button type="button"><img src="/RecImages/Start.gif" width="30" height="50" /></button> &nbsp;&nbsp; &nbsp;
										<div class="spinner-grow spinner-grow-sm text-primary" role="status">
											<span class="visually-hidden">Loading...</span>
										</div>&nbsp; &nbsp;
										<div class="spinner-grow spinner-grow-sm text-secondary" role="status">
											<span class="visually-hidden">Loading...</span>
										</div>&nbsp; &nbsp;
										<div class="spinner-grow spinner-grow-sm text-success" role="status">
											<span class="visually-hidden">Loading...</span>
										</div>&nbsp; &nbsp;
										<div class="spinner-grow spinner-grow-sm text-danger" role="status">
											<span class="visually-hidden">Loading...</span>
										</div>&nbsp; &nbsp;
										<div class="spinner-grow spinner-grow-sm text-warning" role="status">
											<span class="visually-hidden">Loading...</span>
										</div>&nbsp; &nbsp;
										<div class="spinner-grow spinner-grow-sm text-info" role="status">
											<span class="visually-hidden">Loading...</span>
										</div>&nbsp; &nbsp;
										<div class="spinner-grow spinner-grow-sm text-dark" role="status">
											<span class="visually-hidden">Loading...</span>
										</div>
										&nbsp;&nbsp; &nbsp;
										<button onClick={stopRecording} type="button"><img src="/RecImages/stop.gif" width="30" height="50" /></button>
									</>
									:
									<button onClick={startRecording} type="button"><img src="/RecImages/NotStarted.gif" width="60" height="100" onmouseover="Start Recording" alt="Start Recording" /></button>
							}
						</div>
						<video id="localVideo" muted autoplay hidden></video>
						<br />
					</div>
					<div class="col-sm-3">
					</div>
				</div>
			</form>
			<div class="row justify-content-center">
				<div class="col-sm-3">
				</div>
				<div class="col-sm-6 justify-content-center text-center">
					<strong></strong>
					<h5 class="display-7 text-center  text-success"> Transcript:</h5>
					{apiResponse ?
						<div>
							{
								fullThread.map(
									(text, index) => (
										<>
										{text} <br/>
										</>
									
									)
								)								
							}							
							<br/><br/><br/>
							<button onClick={reset} type="button" class="btn btn-danger">Reset and Start Over</button>
						</div>
						:
						loading ? "Generating... Please wait" : ""
					}
				</div>
				<div class="col-sm-3">
				</div>
			</div>
		</>
	);
};


export default GenTextfromCapturedAudioChatbot;

